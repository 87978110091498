import common from './vi/common.json';
import support from 'pages/support/translate/vi.json';
import SupportVi from 'components/layout/topnavigation/translate/vi.json'
import HomeVi from 'pages/home/translate/vi.json'
import SideNavigationVi from 'components/layout/sidenavigation/translate/vi.json';
import ProjectDetailVi from 'pages/project-detail/translate/vi.json';
import ApiDetailVi from 'pages/api-detail/translate/vi.json';
import AppFooterVi from 'components/layout/footer/translate/vi.json';

const Vietnamese = {
    ...common,
    ...support,
    ...SupportVi,
    ...HomeVi,
    ...SideNavigationVi,
    ...ProjectDetailVi,
    ...ApiDetailVi,
    ...AppFooterVi
}
export default Vietnamese
