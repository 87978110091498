import React from 'react';
import Spinner from 'components/core/spinner/Spinner';

const Loader = () => {
    return (
        <div id="loader"
            className="Loader fixed z-50 w-screen h-screen opacity-50 top-0 left-0 bg-[#000000] flex items-center justify-center align-self-center cursor-wait ">
            <Spinner variant="danger"/>
        </div>
    )
}

export default Loader

