import common from './en/common.json';
import support from 'pages/support/translate/en.json';
import SupportEn from 'components/layout/topnavigation/translate/en.json'
import HomeEn from 'pages/home/translate/en.json'
import SideNavigationEn from 'components/layout/sidenavigation/translate/en.json';
import ProjectDetailEn from 'pages/project-detail/translate/en.json';
import ApiDetailEn from 'pages/api-detail/translate/en.json';
import AppFooterEn from 'components/layout/footer/translate/en.json';

const English = {
    ...common,
    ...support,
    ...SupportEn,
    ...HomeEn,
    ...SideNavigationEn,
    ...ProjectDetailEn,
    ...ApiDetailEn,
    ...AppFooterEn
}
export default English
